<template>
  <div class="">
    <div id="enquire" class="p-2 border-2 border-cbd-liteblue">
      <h1 class="pb-5 text-2xl font-semibold text-cbd-liteblue">
        Register Your Interest Now
      </h1>
      <!-- feedback.activamedia.com.sg script begins here --><iframe
        allowTransparency="true"
        style="min-height: 550px; height: inherit; overflow: auto"
        width="100%"
        id="contactform123"
        name="contactform123"
        marginwidth="0"
        marginheight="0"
        frameborder="0"
        src="https://feedback.activamedia.com.sg/my-contact-form-5877588.html"
        ><p>
          Your browser does not support iframes. The contact form cannot be
          displayed. Please use another contact method (phone, fax etc)
        </p></iframe
      ><!-- feedback.activamedia.com.sg script ends here -->
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style>
/* #enquire iframe {
  min-height: 520px !important;
} */
</style>