<template>
  <div
    class="bg-right bg-no-repeat bg-cover lg:bg-center"
    style="background-image: url('/images/main-banner.jpg')"
  >
    <div class="container">
      <div class="py-20 md:py-44 lg:py-36 lg:flex">
        <div
          v-if="!hideEnquiry"
          class="hidden lg:block lg:w-1/2"
          data-aos="fade-down"
        >
          <div id="enquire" class="p-2 border-2 border-cbd-liteblue">
            <h1 class="pb-5 text-2xl font-semibold text-cbd-liteblue">
              Register Your Interest Now
            </h1>
            <!-- feedback.activamedia.com.sg script begins here --><iframe
              allowTransparency="true"
              style="min-height: 540px; height: inherit; overflow: auto"
              width="100%"
              id="contactform123"
              name="contactform123"
              marginwidth="0"
              marginheight="0"
              frameborder="0"
              src="https://feedback.activamedia.com.sg/my-contact-form-5877588.html"
              ><p>
                Your browser does not support iframes. The contact form cannot
                be displayed. Please use another contact method (phone, fax etc)
              </p></iframe
            ><!-- feedback.activamedia.com.sg script ends here -->
          </div>
        </div>
        <div class="lg:w-1/2 lg:pl-4" data-aos="fade-down" data-aos-delay="800">
          <h1
            class="pb-4 text-3xl leading-tight text-left text-white md:text-4xl"
          >
            Experience The New <br class="hidden md:block" />
            <span
              class="text-4xl font-bold md:text-5xl lg:text-6xl text-cbd-liteblue"
              >Business Class</span
            >
          </h1>
          <div class="w-20 h-0.5 bg-cbd-liteblue"></div>
          <div class="mt-5 border-l-2 border-cbd-liteblue">
            <p
              class="pl-2 text-sm text-left text-white lg:text-base md:w-2/3 lg:w-3/4"
            >
              Welcome to a brand new corporate address of international
              influence. Its position within KL Metropolis, affluent
              surroundings and exceptional connectivity make it a true focal
              point where business communities come together
            </p>
          </div>
          <div class="pt-8">
            <a href="https://wa.me/60133257916?text=I'd%20like%20to%20make%20a%20virtual%20consultancy%20about%20The%20Met%20Mont%20Kiara">
              <img src="/images/bg-call.png" alt="" class="w-1/2 md:w-1/3 lg:w-60" />
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    hideEnquiry: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style>
</style>