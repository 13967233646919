<template>
  <div class="py-3 bg-black">
    <div class="container">
      <div class="md:flex md:justify-between md:items-center">
        <img
          src="/images/main-logo.png"
          alt="CBD Properties Sdn Bhd"
          class="w-32 mx-auto md:mx-0"
        />
        <div>
          <h1
            class="pb-2 text-base font-semibold text-center md:pb-0 text-cbd-gray md:text-right md:leading-tight"
          >
            Call Us At
          </h1>
          <div class="w-0.5 h-10 mx-auto bg-cbd-liteblue md:hidden"></div>
          <div class="py-2 text-center text-gray-700 md:py-0">
            <a
              href="tel:+60133257916"
              class="text-lg font-semibold transition duration-300 ease-in-out text-cbd-gray md:text-xl"
              id="cta-btn-top"
              ><i class="pr-2 fas fa-mobile-alt"></i> +6013-325 7916</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style>
</style>