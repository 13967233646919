<template>
  <div>
    <div class="pt-5 md:flex md:justify-center">
      <a
        href="https://wa.me/60133257916?text=I'd%20like%20to%20make%20a%20virtual%20consultancy%20about%20The%20Met%20Mont%20Kiara"
        class="block pb-2 md:px-1"
      >
        <div class="w-64 mx-auto bg-cbd-liteblue">
          <h1 class="py-2 text-white">Book Virtual Consultation</h1>
        </div>
      </a>
      <a href="tel:+60133257916">
        <div class="w-64 mx-auto bg-cbd-liteblue">
          <h1 class="py-2 text-white">Call Us Now</h1>
        </div>
      </a>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style>
</style>