<template>
  <div>
    <WaBtn />
    <!-- header -->
    <MainHeader />
    <!-- end header -->

    <!-- main banner -->
    <MainBanner />
    <!-- end main banner -->

    <!-- mobile enquiry -->
    <div class="py-10 bg-gray-900 lg:hidden">
      <div class="container">
        <EnquireForm class="md:w-2/3 md:mx-auto" />
      </div>
    </div>
    <!-- end mobile enquiry -->

    <!-- sub-banner -->
    <div class="py-8">
      <div class="container">
        <div class="px-3 py-4 border-2 border-gray-900">
          <h1 class="pb-5 text-2xl font-semibold md:text-3xl">
            The
            <span class="text-cbd-liteblue"
              >1st Stratified Premium Grade Corporate Office Tower</span
            >
            <br class="hidden lg:block" />
            in <span class="text-cbd-liteblue">Mont Kiara</span>
          </h1>
          <div class="h-0.5 md:w-2/3 md:mx-auto bg-gray-900"></div>
          <h1
            class="pt-5 text-base font-bold leading-tight text-gray-700 md:text-lg"
          >
            Call Our Trusted Sales Consultants For An Online Guided Sales
            Experience
          </h1>
          <CallButton />
        </div>
      </div>
    </div>

    <!-- about us -->
    <div class="py-10">
      <div class="container">
        <div class="lg:flex lg:items-center">
          <img src="/images/about-us.jpg" alt="" class="lg:w-1/2" />
          <div
            class="pt-5 lg:w-1/2 lg:pl-4 lg:pt-0"
            data-aos="fade-right"
            data-aos-delay="700"
          >
            <h1
              class="pb-5 text-xl font-normal text-left text-gray-900 md:text-2xl"
            >
              KL METROPOLIS A CITY <br /><span
                class="text-2xl font-bold md:text-3xl text-cbd-liteblue"
                >INSPIRED BY VISION</span
              >
            </h1>
            <div class="w-20 h-0.5 bg-cbd-liteblue"></div>
            <p class="pt-5 text-sm text-left text-gray-700">
              Built over 75.5 acres of prime land with a total GDV of RM20
              billion over the next 20 years, KL Metropolis is envisioned to be
              Kuala Lumpur’s “International Trade and Exhibition District”.
              <br /><br />
              As it stands, it is already the current location of the MITEC,
              MITI and MATRADE buildings, making it perfectly poised to boost
              the MICE sector and business tourism, propelling the country to
              become the premier and preferred MICE destination in the region.
            </p>
          </div>
        </div>
      </div>
    </div>
    <!-- end about us -->

    <!-- ART OF PRESTIGE -->
    <div class="py-10">
      <img src="/images/prestige.jpg" alt="ART OF PRESTIGE" class="w-full" />
      <div class="container">
        <div class="pt-5 lg:pt-10" data-aos="fade-down">
          <h1
            class="pb-5 text-2xl font-normal text-left text-gray-900 md:text-3xl"
          >
            ART OF <span class="font-bold text-cbd-liteblue">PRESTIGE</span>
          </h1>
          <div class="w-20 h-0.5 bg-cbd-liteblue"></div>
          <p class="pt-5 text-sm text-left text-gray-700">
            With a stunning arrival experience that is prestigious beyond
            measure and functional beyond expectation, The MET extends a
            distinguished welcome to all with its double-volume 10-metre height
            lobby and bespoke architecture, because every consummate
            professional deserves a grand arrival. This exquisite introduction
            continues through the main point of ingress, where forward-thinking
            interior expressions impress at every glance.
          </p>
        </div>
      </div>
    </div>
    <!-- end ART OF PRESTIGE -->

    <!-- FEATURES -->
    <div class="py-10 bg-gray-100">
      <div class="container">
        <div
          v-for="(item, i) in feature"
          :key="i"
          class="pb-10 lg:flex xl:items-center lg:py-14"
        >
          <img
            :src="item.image"
            :alt="item.alt"
            class="lg:w-1/2"
            :class="item.Order"
          />
          <div class="pt-3 lg:w-1/2 lg:pl-6" :class="item.right">
            <h1
              class="pb-5 text-2xl font-normal text-left text-gray-900 md:text-3xl"
              :class="item.txtright"
              data-aos="fade-down"
            >
              {{ item.title }}
              <span class="font-bold text-cbd-liteblue">{{
                item.txtblue
              }}</span>
            </h1>
            <div class="w-20 h-0.5 bg-cbd-liteblue" :class="item.line"></div>
            <p
              class="pt-5 text-xs text-left text-gray-700 md:text-base"
              :class="item.txtright"
              data-aos="fade-down"
            >
              {{ item.p }}
            </p>
            <div v-if="item.title2" class="pt-10">
              <h1
                v-if="item.title2"
                class="pb-5 text-2xl font-normal text-left text-gray-900 md:text-3xl"
                data-aos="fade-down"
              >
                {{ item.title2 }}
                <span
                  v-if="item.txtblue2"
                  class="font-bold text-cbd-liteblue"
                  >{{ item.txtblue2 }}</span
                >
              </h1>
              <div class="w-20 h-0.5 bg-cbd-liteblue"></div>
              <p
                v-if="item.p2"
                class="pt-5 text-xs text-left text-gray-700 md:text-base"
                data-aos="fade-down"
              >
                {{ item.p2 }}
              </p>
            </div>
            <ul
              v-if="item.list"
              class="pt-5 pl-2 text-sm font-medium text-left list-disc list-inside "
              :class="item.txtright"
              data-aos="fade-down"
            >
              <li v-for="(sub_list, i) in item.list" :key="i" class="pb-1 pl-2">
                {{ sub_list }}
              </li>
            </ul>
          </div>
        </div>
        <CallButton />
        <!-- youtube section -->
        <div class="py-6 idvideo lg:max-w-3xl lg:mx-auto xl:max-w-4xl">
          <youtube :video-id="videoId" ref="youtube"></youtube>
        </div>
        <!-- end youtube section -->

        <!-- <div
          class="py-2 mx-auto transition duration-200 ease-in-out border-2 w-36 border-cbd-liteblue hover:bg-cbd-liteblue"
        >
          <a href="tel:+60133257916" id="call-btn-middle" class="text-base"
            >Call Us Now</a
          >
        </div> -->
      </div>
    </div>
    <!-- end FEATURES -->
    <!-- DEFINING THE 3RD SPACE -->
    <div class="py-10 bg-cbd-liteblue">
      <div class="container">
        <div>
          <h1 class="pb-5 text-2xl font-normal text-gray-700 md:text-4xl">
            DEFINING THE <span class="font-bold text-white">3RD SPACE</span>
          </h1>
          <div class="w-20 h-0.5 mx-auto bg-white"></div>
          <h1 class="pt-5 text-xl font-semibold text-gray-700 uppercase">
            creating a professional <br />
            community and going <br />
            beyond just an office address
          </h1>
          <p class="pt-6 text-sm text-gray-800 lg:w-3/4 lg:mx-auto">
            By definition, the 3rd Space is the realm that sits between our
            personal and professional selves, often revolving around the
            benefits presented by recreational elements. The MET celebrates the
            3rd Space philosophy with a rich offering of next-level business
            facilities and landscaped outdoor areas that contribute towards
            creating an environment that is conducive to high levels of
            sociability and interaction – all these will be available on the
            podium deck at level 9.
          </p>
        </div>
      </div>
    </div>
    <!-- end DEFINING THE 3RD SPACE -->
    <div class="py-10 bg-cbd-litegray">
      <div class="lg:flex xl:items-center lg:pb-6">
        <img
          src="/images/facilities.jpg"
          alt="DEFINING THE 3RD SPACE"
          class="lg:w-1/2"
        />
        <div class="px-3 pt-10 lg:w-1/2">
          <div
            v-for="(item, i) in benefit"
            :key="i"
            class="flex items-start pb-6 md:justify-center"
            data-aos="fade-down"
          >
            <div class="w-16">
              <img :src="item.image" :alt="item.alt" class="" />
            </div>
            <div class="w-3/4 pl-3">
              <h1 class="text-base font-bold text-left text-gray-800">
                {{ item.alt }}
              </h1>
              <p class="pt-2 text-xs text-left font-gray-700">
                {{ item.p }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <CallButton />
    </div>

    <!-- layout -->
    <div class="py-10">
      <div class="container">
        <carousel
          :autoplay="true"
          :loop="true"
          :responsive="{
            0: { items: 1, nav: false },
          }"
        >
          <div v-for="(item, i) in layout" :key="i">
            <h1
              class="pb-5 text-xl font-normal text-left text-gray-900 lg:text-2xl"
            >
              TOWER A <br />
              <span class="text-3xl font-bold lg:text-4xl text-cbd-liteblue"
                >{{ item.level }}
              </span>
              <br />
              FLOOR PLANS
            </h1>
            <div class="lg:flex lg:items-center">
              <img :src="item.image" :alt="item.alt" class="w-full lg:w-1/2" />
              <div class="lg:w-1/2">
                <div
                  class="px-2 py-3 border-2 md:w-2/3 md:mx-auto border-cbd-liteblue"
                >
                  <h1 class="text-base leading-tight text-gray-700">
                    {{ item.feature }}
                  </h1>
                  <p v-html="item.text" class="pt-4">
                    {{ item.text }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </carousel>
      </div>
    </div>

    <!-- lease -->
    <div class="py-8 bg-cbd-liteblue">
      <div class="container">
        <div
          class="text-xl font-normal leading-tight text-gray-700 md:text-3xl"
        >
          <h1 class="pb-5">
            NOT LOOKING FOR LONG TERMS <br class="hidden md:block" />
            COMMITMENTS?
          </h1>
          <h1 class="pb-4 text-2xl font-bold text-white md:text-4xl">
            RENTAL AVAILABLE!
          </h1>
        </div>
        <div
          class="py-2 mx-auto text-white transition duration-200 ease-in-out border-2 border-white hover:text-cbd-liteblue w-36 hover:bg-white"
        >
          <a href="tel:+60133257916" id="call-btn-middle" class="text-base"
            >Call Us Now</a
          >
        </div>
      </div>
    </div>
    <!-- end lease -->

    <!-- gallery map -->
    <div class="">
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3983.69421341666!2d101.65791535055477!3d3.1749582538669525!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31cc488a69666345%3A0xf2ff6bdb6f5b6263!2sThe%20MET%20Show%20Gallery!5e0!3m2!1sen!2smy!4v1618365372434!5m2!1sen!2smy"
        width="100%"
        style="border: 0"
        allowfullscreen=""
        loading="lazy"
        class="h-64 md:h-80 lg:h-96"
      ></iframe>
    </div>

    <!-- footer -->
    <div
      class="py-10 bg-center bg-no-repeat bg-cover"
      style="background-image: url('/images/footer.jpg')"
    >
      <div class="container">
        <div class="lg:flex lg:items-center">
          <!-- <EnquireForm class="lg:w-1/2 lg:order-2" /> -->
          <div class="pt-6 lg:w-1/2 lg:pr-5">
            <h1
              class="text-base font-semibold text-center text-white lg:text-left"
            >
              <b>The MET Show Gallery</b> : J-G-13A, Ground Floor, Solaris Mont
              Kiara, 2 Jalan Solaris, Mont Kiara, 50480 Kuala Lumpur
            </h1>
            <h1
              class="pt-4 pb-6 text-base font-semibold text-center text-white lg:text-left"
            >
              CBD Office:
              <a href="https://g.page/cbd-properties-HQ?share"
                >E-G-7 & E-1-7, Capital 5, Oasis Square 2A, Jalan PJU 1A/7A, Ara
                Damansara, 47301 Petaling Jaya, Selangor, Malaysia</a
              >
            </h1>
            <a href="tel:+60133257916">
            <div class="mx-auto lg:mx-0 w-60 bg-cbd-liteblue lg:w-56">
              <h1
                class="py-3 text-lg font-bold text-center text-white"
              >
                Call Us : 013-3257916
              </h1>
            </div>
            </a>
            <div class="flex items-center justify-center pt-5">
              <img
                src="/images/main-logo.png"
                alt=""
                class="w-32 mx-auto lg:w-1/3"
              />
              <img
                src="/images/cbd-logo.png"
                alt=""
                class="w-32 mx-auto lg:w-1/3"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- end footer -->
    <Copyright />
  </div>
</template>

<script>
// @ is an alias to /src
import carousel from "vue-owl-carousel";
import EnquireForm from "@/components/EnquireForm.vue";
import WaBtn from "@/components/WaBtn.vue";
import MainHeader from "@/components/MainHeader.vue";
import MainBanner from "@/components/MainBanner.vue";
import Copyright from "@/components/Copyright.vue";
import CallButton from "@/components/CallButton.vue"

export default {
  name: "Home",
  components: {
    carousel,
    EnquireForm,
    WaBtn,
    MainHeader,
    MainBanner,
    Copyright,
    CallButton
  },
  data() {
    return {
      feature: [
        {
          image: "/images/feature-1.jpg",
          alt: "HUMAN-FOCUSED FEATURES",
          title: "HUMAN-FOCUSED",
          txtblue: " FEATURES",
          p: "Every component has been crafted with a human-focused approach, defined by characteristics that maximize security, convenience, comfort and inspiration for the individuals plying their trade here.",
          list: [
            " 3.6-metre ceiling height (beam-free design system)",
            " 23-passenger lifts with destination control system(high & low zones for Tower A)",
            " Grand lobby featuring 10-metre high ceilings",
            "Curtain wall building façade for Tower A",
            " Individual pantry points for all units",
            " Toilet points for units sized 1,500 sf and above",
            " 0.5 acres of landscapes & green areas",
            "Visitor Management System",
          ],
        },
        {
          image: "/images/feature-2.jpg",
          Order: "order-2",
          txtright: ["lg:text-right"],
          line: "lg:ml-auto",
          right: "pr-6",
          alt: "FORWARD-THINKING INNOVATIONS",
          title: "FORWARD-THINKING",
          txtblue: "INNOVATIONS",
          p: "Thanks to its combination of spaciousness, versatility and high functionality, the needs of individual establishments are well and truly catered to at The MET Corporate Towers",
          list: [
            "Vacant Car Park Indicator System",
            "GBI-certified building with Rainwater Harvesting System ",
            "Fibre optic-ready status & WIFI-ready in common areas",
            "Security gauntlet & CCTV system",
            "Electric/hybrid car charging station",
            "Building Automation System",
          ],
        },
        {
          image: "/images/feature-3.jpg",
          alt: "STATE-OF-THE-ART SECURITY",
          title: "STATE-OF-THE-ART",
          txtblue: "SECURITY",
          p: "Turnstile security lift barriers afford enhanced safety levels, while dedicated low and high zone lifts provide efficient access to all floors with minimal waiting times.",

          title2: "HIGHER",
          txtblue2: "PERSPECTIVES",
          p2: "From the landscaped podium level up till the 42nd floor, the stunning cityscapes embrace each space within The MET Corporate Towers, serving as an inspirational view across all levels.",
        },
      ],
      benefit: [
        {
          image: "/images/icon-1.png",
          alt: "GYM & RECREATIONAL CENTRE",
          p: "A modern indoor/outdoor fitness studio for high performance and efficient workouts centered around a large 2,000 sf male & female shower, sauna & locker facilities.",
        },
        {
          image: "/images/icon-2.png",
          alt: "BUSINESS AUDITORIUM",
          p: "An exclusive space to host trainings and seminars for up to 80 pax complete with a tastefully appointed breakout lounge area",
        },
        {
          image: "/images/icon-3.png",
          alt: "BUSINESS CENTRE",
          p: "A professionally managed business centre with an elegant reception area, gourmet pantry and 6 versatile meeting rooms ranging from 10-seater to 20-seater boardroom style layouts.",
        },
        {
          image: "/images/icon-4.png",
          alt: "INTEGRATED LANDSCAPE AREA",
          p: "A landscaped breakout area, with seamlessly integrated spaces that promote interaction. Also ideal for casual meetings and gatherings.",
        },
        {
          image: "/images/icon-5.png",
          alt: "GLASS BOX FUNCTION HALL",
          p: "Our signature meeting facility with stunning, unobstructed views of KL Metropolis and an expansive seating capacity of up to 300 people.",
        },
      ],
      layout: [
        {
          level: "PRIME LEVELS",
          image: "/images/prime-level.jpg",
          feature:
            "Highly functional and versatile units, with efficient sizes for varying needs",
          text: "Levels : 10 - 25 <br> Built-up : 818 sf - 2,584 sf <br><br> 12 layout variants",
        },
        {
          level: "PREMIER LEVELS",
          image: "/images/premier-level.jpg",
          feature: "Featuring larger layouts with 4 exclusive units per floor",
          text: "Levels : 26 - 31 <br> Built-up : 3,606 sf - 4,231 sf <br><br> 4 layout variants",
        },
        {
          level: "PRESTIGE LEVELS",
          image: "/images/premier-level-2.jpg",
          feature:
            "For elite businesses, seeking maximised space across an entire floorplate",
          text: "Levels : 32 - 39 <br> Built-up : 16,104 sf",
        },
      ],
      videoId: "elnnzoYvbzw",
    };
  },
};
</script>

<style>
/* youtube width */
.idvideo iframe {
  width: 100%;
}
</style>