<template>
  <div class="py-3 bg-black">
    <div class="container mx-auto">
      <h2 class="text-sm font-medium text-center text-white md:text-sm">
        Website maintained by Activa Media. All Rights Reserved.
      </h2>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style>
</style>